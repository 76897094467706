import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GoogleLogin } from 'react-google-login';
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { ArrowRight, EyeSlash, EyeFill } from "react-bootstrap-icons";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const onInputChangeemail = (e) => {
    setemail(e.target.value);
  };


  const onInputChangepassword = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const Submit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      if (!email || !password) {
        // Handle null or undefined values for email or password
        toast.error("Email or password is missing!");
        setLoading(false);
        return;
      }

      let url = `${process.env.REACT_APP_API_URL}login-customer`;

      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      // formData.append('device_id', 'hello');
      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config).then(function (response) {

        if (response.data.status == 1) {
          console.warn(response.data.success);
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: (response.data.data.message),
            timer: 2000,
          }).then((result) => {
            navigate('/home');
            console.warn(response.data)
            localStorage.setItem("userid", response.data.data.id);
            localStorage.setItem("bear_token", response.data.bear_token);
            localStorage.setItem("broker_id", response.data.data.broker_id);
         
          });
        } else {
          Swal.fire({
            icon: "error",
            title: (response.data.message),
            text: response.data.message,
            timer: 2000,
          });
        }
      })
        .finally(() => {
          // setTimeout(() =>{
          setLoading(false);
          // },5000);
        });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }

  };
  // const Submit = async (e) => {
  //   e.preventDefault();
  
  //   setLoading(true);
  
  //   try {
  //     if (!email || !password) {
  //       toast.error("Email or password is missing!");
  //       setLoading(false);
  //       return;
  //     }
  //     if (email === "demo@gmail.com" && password === "123456") {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Success!",
  //         text: "Login successful!",
  //         timer: 2000,
  //       }).then((result) => {
  //         navigate('/home');
  //         localStorage.setItem("userid", "dummyUserId");
  //         localStorage.setItem("bear_token", "dummyBearToken");
  //       });
  
  //       setLoading(false); 
  //       return; 
  //     }
  
  //     let url = `${process.env.REACT_APP_API_URL}login-customer`;
  
  //     const formData = new FormData();
  //     formData.append("email", email);
  //     formData.append("password", password);
  
  //     const config = {
  //       method: "POST",
  //       url: url,
  //       data: formData,
  //     };
  
  //     axios(config)
  //       .then(function (response) {
  //         if (response.data.status === 1) {
  //           console.warn(response.data.success);
  //           Swal.fire({
  //             icon: "success",
  //             title: "Success!",
  //             text: response.data.data.message,
  //             timer: 2000,
  //           }).then((result) => {
  //             navigate('/home');
  //             localStorage.setItem("userid", response.data.data.id);
  //             localStorage.setItem("bear_token", response.data.bear_token);
  //           });
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: response.data.message,
  //             text: response.data.message,
  //             timer: 2000,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error occurred during login:", error);
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error!",
  //           text: "An error occurred during login. Please try again later.",
  //           timer: 2000,
  //         });
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  
  //   } catch (error) {
  //     console.error("There was a problem with the login:", error);
  //     setLoading(false);
  //   }
  // };
  

  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="assets/img/icons/spot-illustrat ions/bg-shape.png"
              alt=""
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="assets/img/icons/spot-illustrations/shape-1.png"
              alt=""
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0 h-100">
                  <div className="col-md-5 text-center bg-card-gradient">
                    <div
                      className="position-relative p-4 pt-md-5 pb-md-7"
                      data-bs-theme="light"
                    >
                      <div
                        className="bg-holder bg-auth-card-shape"
                        style={{
                          backgroundImage:
                            "url(assets/img/icons/spot-illustrations/half-circle.png)"
                        }}
                      />
                      {/*/.bg-holder*/}
                      <div className="z-1 position-relative">
                        <a
                          className="link-light mb-4 font-sans-serif fs-5 d-inline-block fw-bolder"
                          href="index.php"
                        >
                          Swan
                        </a>
                        <p className="opacity-75 text-white">
                          With the power of Swan, you can now focus only on
                          functionaries for your digital products, while leaving the
                          UI design on us!
                        </p>
                      </div>
                    </div>
                    <div
                      className="mt-3 mb-4 mt-md-4 mb-md-5"
                      data-bs-theme="light"
                    >
                      <p className="text-white">
                        Don't have an account?
                        <br />
                        <a
                          className="text-decoration-underline link-light"
                          href=""
                        >
                          Get started!
                        </a>
                      </p>
                      <p className="mb-0 mt-4 mt-md-5 fs-10 fw-semi-bold text-white opacity-75">
                        Read our{" "}
                        <a
                          className="text-decoration-underline text-white"
                          href="#!"
                        >
                          terms
                        </a>{" "}
                        and{" "}
                        <a
                          className="text-decoration-underline text-white"
                          href="#!"
                        >
                          conditions{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-7 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <div className="row flex-between-center">
                        <div className="col-auto">
                          <h3>Account Login</h3>
                        </div>
                      </div>
                      <form>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="card-email">
                            Email address
                          </label>
                          <input
                            value={email}
                            onChange={onInputChangeemail}
                            className="form-control"
                            id="card-email"
                            type="email"
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="form-label" htmlFor="card-password">
                              Password
                            </label>
                          </div>
                          <div className="input-group">
                            <input
                              value={password}
                              onChange={onInputChangepassword}
                              className="form-control"
                              id="card-password"
                              type={showPassword ? "text" : "password"}
                            />
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <EyeSlash className="fs-3 eye"  style={{height:"21px"}}/>
                              ) : (
                                <EyeFill className="fs-3 eye" style={{height:"21px"}}/>
                              )}
                            </button>
                          </div>

                        </div>
                        <div className="row flex-between-center">
                          <div className="col-auto">
                            <div className="form-check mb-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="card-checkbox"
                                checked={rememberMe}
                                onChange={handleRememberMe}
                              />
                              <label
                                className="form-check-label mb-0"
                                htmlFor="card-checkbox"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <Link className="fs-10" to="/Forgetpassword">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                        <div className="mb-3">
                          <button
                            disabled={loading}
                            onClick={Submit}
                            // to="/home"
                            className="btn btn-primary d-block w-100 mt-3"
                            type="submit"
                            name="submit"
                          >
                            Log in
                            {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                          </button>
                        </div>
                        {/* <div className="mb-3">
                          <Link
                            to="/Register"
                            className="btn btn-primary d-block w-100 mt-3"
                            type="submit"
                            name="submit"
                          >
                            Register
                          </Link>
                        </div> */}
                      </form>
                      <div className="position-relative mt-4">
                        <hr />
                        <div className="divider-content-center">or log in with</div>
                      </div>
                      {/* <div className="row g-2 mt-2">
                    <div className="col-sm-6">
                      <Link
                        className="btn btn-outline-google-plus btn-sm d-block w-100"
                        href="#"
                      >
                        <span
                          className="fab fa-google-plus-g me-2"
                          data-fa-transform="grow-8"
                        />{" "}
                        google
                      </Link>
                    </div>
                    <div className="col-sm-6">
                      <a
                        className="btn btn-outline-facebook btn-sm d-block w-100"
                        href="#"
                      >
                        <span
                          className="fab fa-facebook-square me-2"
                          data-fa-transform="grow-8"
                        />{" "}
                        facebook
                      </a>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>

  )
}
