import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import userprofile from "../assets/img/team/avatar.png";
import { FaEdit } from "react-icons/fa";
export default function PersonalDetails() {
  const broker_id = localStorage.getItem("broker_id");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    altername_phone: "",
    joining_date: "",
    profile: null,
    // password: "", // Added password field
  });
  const [errors, setErrors] = useState({});
  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("broker_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-customer-Profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data.data;
      setFormData(data[0]);
      console.warn("User Profile Data:", data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
  };

  // const validateName = (value) => {
  //   if (!value.trim()) {
  //     return "Name is required";
  //   }
  //   return "";
  // };
  const validateName = (value) => {
    if (!value || !value.trim()) {
      return "Name is required";
    }
    return "";
  };

  const validateEmail = (value) => {
    if (!value.trim()) {
      return "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      return "Email address is invalid";
    }
    return "";
  };

  const validatePhone = (value) => {
    if (!value.trim()) {
      return "Phone number is required";
    } else if (!/^[0-9]+$/.test(value)) {
      return "Phone number is invalid";
    }
    return "";
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    for (const field in formData) {
      switch (field) {
        case "name":
          newErrors[field] = validateName(formData[field]);
          break;
        case "email":
          newErrors[field] = validateEmail(formData[field]);
          break;
        case "phone":
          newErrors[field] = validatePhone(formData[field]);
          break;
        default:
          break;
      }
      if (newErrors[field]) {
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const token = localStorage.getItem("bear_token");

      let formDataToUpdate = new FormData();
      formDataToUpdate.append("name", formData.name);
      formDataToUpdate.append("email", formData.email);
      formDataToUpdate.append("address", formData.address);
      formDataToUpdate.append("broker_id", broker_id);
      formDataToUpdate.append("phone", formData.phone);
      formDataToUpdate.append("altername_phone", formData.altername_phone);
      formDataToUpdate.append("joining_date", formData.joining_date);
      formDataToUpdate.append("status", "1");
      if (newImage) {
        formDataToUpdate.append("profile", newImage);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}update-customer-Profile`,
        formDataToUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = response.data.status;
      getProfile();
      if (data === 1) {
        const MySwal = withReactContent(Swal);
        
        Swal.fire({
          title: 'Success',
          text: 'Profile Updated Successfully!!',
          icon: 'success',
          timer: 2000, 
          showConfirmButton: true 
      });
      }
      console.warn("User Profile Data:", data);
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Personal Details</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-lg-12">
          <div className="card mb-3">
            <div className="card-body bg-body-tertiary">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-lg-6 ">
                  <div className="avatar position-relative   avatar_image_width d-flex justify-content-center mx-auto">
                    <img
                      className="rounded-circle img-thumbnail shadow-sm mx-auto"
                      src={
                        formData.profile
                          ? `http://192.168.1.3:5000/backend/uploads/customer/${formData.profile}`
                          : userprofile
                      }
                      width={100}
                      height={100}
                      alt=""
                    />
                    <label htmlFor="profile" className="custom-file-upload ">
                      <FaEdit className="edit-icon" />
                      <input
                        id="profile"
                        type="file"
                        name="profile"
                        onChange={handleImageChange}
                        style={{
                          position: "absolute",
                          opacity: 0,
                          top: 0,
                          left: 0,
                          cursor: "pointer",
                        }}
                      />
                    </label>
                  </div>

                  {/* <FaEdit  onChange={handleImageChange}/>
                  <input type="file" name="profile" onChange={handleImageChange} /> */}
                </div>

                <div className="col-lg-6">
                  <div>
                    <label className="form-label" htmlFor="user-name">
                      User Name
                    </label>
                    <input
                      className="form-control"
                      id="user-name"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                  </div>
                  <div>
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <label className="form-label" htmlFor="address">
                    Address
                  </label>
                  <input
                    className="form-control"
                    id="address"
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                  {errors.address && (
                    <div className="text-danger">{errors.address}</div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label className="form-label" htmlFor="phonenumber">
                    Phone Number
                  </label>
                  <input
                    className="form-control"
                    id="phonenumber"
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <div className="text-danger">{errors.phone}</div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label className="form-label" htmlFor="apn">
                    Alternative Phone Number
                  </label>
                  <input
                    className="form-control"
                    id="apn"
                    type="text"
                    name="altername_phone"
                    value={formData.altername_phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label" htmlFor="jd">
                    Joining Date
                  </label>
                  <input
                    className="form-control"
                    id="jd"
                    // type="date"
                    name="joining_date"
                    value={formData.joining_date}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                {/* <div className="col-lg-6">
                  <label className="form-label" htmlFor="ChangePassword">
                    Change Password
                  </label>
                  <input
                    className="form-control"
                    id="ChangePassword"
                    type="password" // Changed to password type
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div> */}

                <div className="col-lg-12 d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Update{" "}
                  </button>
                  <button className="btn btn-primary mx-1">Log Out </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
