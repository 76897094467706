import React ,{useEffect} from 'react';
import userprofile from "../assets/img/team/avatar.png";
import { IoIosNotifications } from "react-icons/io";
import  {FaIcons} from "react-icons/fa";
import {Link,useNavigate} from 'react-router-dom'
const Navbar = () => {
  const navigate = useNavigate();
  const Profileimage = localStorage.getItem("profileimage");
  const baseurl = localStorage.getItem("baseurl");
  useEffect(() => {
 
    const user_id = localStorage.getItem("userid");
    const token = localStorage.getItem("bear_token");
 
    const broker_id = localStorage.getItem("broker_id");

    if (user_id == null) {
      window.location.href = '/';
    };
    if (token == null) {
      window.location.href = '/';
    }if(broker_id == null){
      window.location.href ='/'
    }
    else if(token){
    
      navigate('/Home');
    }
  }, [])
  const logout = (e) => {
    e.preventDefault();
    // localStorage.removeItem(user_id);
    localStorage.clear();
    navigate('/');
  }

  return (
    <div className="navbar p-0">
      <nav
  className="navbar navbar-light navbar-glass navbar-top navbar-expand-lg"
  data-double-top-nav="data-double-top-nav"
>
  <div className="w-100">
    <div className="d-flex flex-between-center">
      <button
        className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarDoubleTop"
        aria-controls="navbarDoubleTop"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span className="navbar-toggle-icon">
          <span className="toggle-line" />
        </span>
      </button>
   
      <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
        <li className="nav-item ps-2 pe-0">
          <div className="dropdown theme-control-dropdown">
          <IoIosNotifications className='icon_size text-dark'/>

           
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait"
            id="navbarDropdownNotification"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-hide-on-body-scroll="data-hide-on-body-scroll"
          >
          </a>
          <div
            className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
            aria-labelledby="navbarDropdownNotification"
          >
            <div className="card card-notification shadow-none">
              <div className="card-header">
                <div className="row justify-content-between align-items-center">
                  <div className="col-auto">
                    <h6 className="card-header-title mb-0">Notifications</h6>
                  </div>
                  <div className="col-auto ps-0 ps-sm-3">
                    <a className="card-link fw-normal" href="#">
                      Mark all as read
                    </a>
                  </div>
                </div>
              </div>
              <div className="scrollbar-overlay" style={{ maxHeight: "19rem" }}>
                <div className="list-group list-group-flush fw-normal fs-10">
                  <div className="list-group-title border-bottom">NEW</div>
                  <div className="list-group-item">
                    <a
                      className="notification notification-flush notification-unread"
                      href="#!"
                    >
                      <div className="notification-avatar">
                        <div className="avatar avatar-2xl me-3">
                          <img
                            className="rounded-circle"
                            src="assets/img/team/1-thumb.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="notification-body">
                        <p className="mb-1">
                          <strong>Emma Watson</strong> replied to your comment :
                          "Hello world 😍"
                        </p>
                        <span className="notification-time">
                          <span className="me-2" role="img" aria-label="Emoji">
                            💬
                          </span>
                          Just now
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="list-group-item">
                    <a
                      className="notification notification-flush notification-unread"
                      href="#!"
                    >
                      <div className="notification-avatar">
                        <div className="avatar avatar-2xl me-3">
                          <div className="avatar-name rounded-circle">
                            <span>AB</span>
                          </div>
                        </div>
                      </div>
                      <div className="notification-body">
                        <p className="mb-1">
                          <strong>Albert Brooks</strong> reacted to
                          <strong>Ablert satnam</strong>
                          status
                        </p>
                        <span className="notification-time">
                          <svg
                            className="svg-inline--fa fa-gratipay fa-w-16 me-2 text-danger"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="gratipay"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 496 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm114.6 226.4l-113 152.7-112.7-152.7c-8.7-11.9-19.1-50.4 13.6-72 28.1-18.1 54.6-4.2 68.5 11.9 15.9 17.9 46.6 16.9 61.7 0 13.9-16.1 40.4-30 68.1-11.9 32.9 21.6 22.6 60 13.8 72z"
                            />
                          </svg>
                          {/* <span class="me-2 fab fa-gratipay text-danger"></span> Font Awesome fontawesome.com */}
                          9hr
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="list-group-title border-bottom">EARLIER</div>
                  <div className="list-group-item">
                    <a className="notification notification-flush" href="#!">
                      <div className="notification-avatar">
                        <div className="avatar avatar-2xl me-3">
                          <img
                            className="rounded-circle"
                            src="assets/img/icons/weather-sm.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="notification-body">
                        <p className="mb-1">
                          The forecast today shows a low of 20℃ in California.
                          See today's weather.
                        </p>
                        <span className="notification-time">
                          <span className="me-2" role="img" aria-label="Emoji">
                            🌤️
                          </span>
                          1d
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="list-group-item">
                    <a
                      className="border-bottom-0 notification-unread  notification notification-flush"
                      href="#!"
                    >
                      <div className="notification-avatar">
                        <div className="avatar avatar-xl me-3">
                          <img
                            className="rounded-circle"
                            src="assets/img/logos/oxford.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="notification-body">
                        <p className="mb-1">
                          <strong>University of Oxford</strong> created an event
                          : "Causal Inference Hilary 2019"
                        </p>
                        <span className="notification-time">
                          <span className="me-2" role="img" aria-label="Emoji">
                            ✌️
                          </span>
                          1w
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="list-group-item">
                    <a
                      className="border-bottom-0 notification notification-flush"
                      href="#!"
                    >
                      <div className="notification-avatar">
                        <div className="avatar avatar-xl me-3">
                          <img
                            className="rounded-circle"
                            src="assets/img/team/10.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="notification-body">
                        <p className="mb-1">
                          <strong>James Cameron</strong> invited to join the
                          group: United Nations International Children's Fund
                        </p>
                        <span className="notification-time">
                          <span className="me-2" role="img" aria-label="Emoji">
                            🙋‍
                          </span>
                          2d
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-footer text-center border-top">
                <a
                  className="card-link d-block"
                  href="app/social/notifications.html"
                >
                  View all
                </a>
              </div>
            </div>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link pe-0 ps-2"
            id="navbarDropdownUser"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="avatar avatar-xl">
              <img
                className="rounded-circle"
                src={
                  Profileimage
                    ? `${baseurl}/${Profileimage}`
                    : userprofile
                }
                // src={proifle}
                // profileimage
                alt=""
              />
            </div>
          </a>
          <div
            className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
            aria-labelledby="navbarDropdownUser"
          >
            <div className="bg-white dark__bg-1000 rounded-2 py-2">
              <Link className="dropdown-item" to="/profile">
              {/* <Link className="dropdown-item" to="/Personaldetails"> */}
                Profile &amp; account
              </Link>
              <Link className="dropdown-item" to="/settings">
                Settings
              </Link>
              <Link className="dropdown-item" onClick={logout}>
                Logout
              </Link>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

    </div>
  );
};
export default Navbar;
