import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Setting from "./pages/Settings";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import "./App.css";
import { Container } from "react-bootstrap";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Personaldetails from "./pages/Personaldetails";
import Termsandcondition from "./pages/Termsandcondition";
import Aboutus from "./pages/Aboutus";
import Login from "./components/Login";
import Changepasssword from "./pages/Changepasssword";
import Forgetpassword from "./pages/Forgetpassword";
import Faq from "./pages/Faq";
import Investmentview from "./pages/Investmentview";
import Totalinvestment from "./pages/Totalinvestment";
import Contactus from "./pages/Contactus";
import Privacypolicy from "./pages/Privacypolicy";
// import stylesheet fiels
function App() {
  return (
    <React.Fragment>
      
      <Container fluid>
        <Router>
          <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Forgetpassword" element={<Forgetpassword />} />
          <Route path="/Changepasssword" element={<Changepasssword />} />
          <Route path="/register" element={<Register />} />

          <Route path="/*" element={
            <>        
              <Sidebar />
          <div className="content" style={{ marginLeft: "15.625rem" }}>
            <Navbar />
            <main className="main" id="top">
              <Routes>
              <Route path="/home" element={<Home />} />
                <Route path="/Profile" element={<Profile />} />
                <Route path="/Personaldetails" element={<Personaldetails />} />
                <Route path="/Investmentview" element={<Investmentview />} />
                <Route path="/Totalinvestment" element={<Totalinvestment />} />
                <Route path="/Contactus" element={<Contactus />} />
                <Route path="/Privacypolicy" element={<Privacypolicy />} />
              {/* page design */}

                <Route path="/Aboutus" element={<Aboutus />} />
                <Route path="/Termsandcondition" element={<Termsandcondition />}/>
                <Route path="/Faq" element={<Faq />} />
                <Route path="/settings" element={<Setting />} />
            
           
              </Routes>
            </main>
          </div>
          <Footer />
          </>
        }/>
        </Routes>
              
        </Router>
      </Container>
    </React.Fragment>
  );
}
export default App;
