import React, { useState } from 'react';
import sss from './imverstment.json';

export default function Investmentview() {
  const [data, setData] = useState(sss);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState('');
  const itemsPerPage = 5;

  // Filter data based on filterText (only ID)
  const filteredData = data.filter(item => item.ID.includes(filterText));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`btn btn-sm mx-1 ${i === currentPage ? 'btn-primary' : 'btn-outline-primary'}`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  return (
    <div className="container-fluid">
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage: "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Investment Details</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div id="tableExample3" className="padding-15 card-body">
          <div className="row justify-content-end g-0">
            <div className="col-auto col-sm-5 mb-3">
              <form>
                <div className="input-group">
                  <input
                    className="form-control form-control-sm shadow-none search"
                    type="search"
                    placeholder="Search by ID..."
                    aria-label="search"
                    value={filterText}
                    onChange={handleFilterChange}
                  />
                  {/* <div className="input-group-text bg-transparent">
                    <span className="fa fa-search fs-10 text-600" />
                  </div> */}
                </div>
              </form>
            </div>
          </div>
          <div className="table-responsive scrollbar">
            <table className="table table-sm table-striped fs-10 mb-0 overflow-hidden">
              <thead className="bg-200">
                <tr>
                  <th className="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="InvestmentID">
                    ID
                  </th>
                  <th className="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="UserID">
                    User ID
                  </th>
                  <th className="text-900 sort align-middle white-space-nowrap text-start pe-4" data-sort="InvestmentName">
                    Name
                  </th>
                  <th className="text-900 sort align-middle white-space-nowrap text-start pe-4" data-sort="InvestmentValue">
                    Value
                  </th>
                  <th className="text-900 sort align-middle white-space-nowrap text-start pe-4" data-sort="PerformanceReturn">
                    Performance & Return
                  </th>
                  <th className="text-900 sort align-middle white-space-nowrap text-start pe-4" data-sort="InvestmentTerm">
                    Term
                  </th>
                  <th className="text-900 sort align-middle white-space-nowrap text-start pe-4" data-sort="payment">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="list" id="table-purchase-body">
                {currentItems.map((investment) => (
                  <tr className="btn-reveal-trigger" key={investment.ID}>
                    <th className="align-middle white-space-nowrap text-start name">
                      <a href="#">{investment.ID}</a>
                    </th>
                    <td className="align-middle white-space-nowrap text-start email">
                      {investment.user_id}
                    </td>
                    <td className="align-middle white-space-nowrap text-start email">
                      {investment.Name}
                    </td>
                    <td className="align-middle white-space-nowrap text-start email">
                      {investment.value}
                    </td>
                    <td className="align-middle white-space-nowrap text-start email">
                      {investment.p}
                    </td>
                    <td className="align-middle white-space-nowrap text-start email">
                      {investment.Tearm}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <button className="btn-sm btn btn-primary">View</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-auto d-flex text-center">
              {/* <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <span>Previous</span>
              </button> */}
              {renderPageNumbers()}
              {/* <button
                className="btn btn-sm btn-primary px-4 ms-2"
                type="button"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <span>Next</span>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


// import React, { useState } from 'react'
// import sss from './imverstment.json'

// export default function Investmentview() {
//   // const data= imverstment;
//   const [data , setData] = useState(sss)
//   console.warn(data)
//   return (
//     <div className="container-fluid">
//     <div className="card mb-3">
//       <div
//         className="bg-holder d-none d-lg-block bg-card"
//         style={{
//           backgroundImage:
//             "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
//         }}
//       />
//       {/*/.bg-holder*/}
//       <div className="card-body position-relative">
//         <div className="row">
//           <div className="col-lg-8">
//             <h3 className="mb-0">Investment Details</h3>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="card">
//       <div
//         id="tableExample3"
//         className="padding-15 card-body"
//         data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
//       >
//         <div className="row justify-content-end g-0">
//           <div className="col-auto col-sm-5 mb-3">
//             <form>
//               <div className="input-group">
//                 <input
//                   className="form-control form-control-sm shadow-none search"
//                   type="search"
//                   placeholder="Search..."
//                   aria-label="search"
//                 />
//                 <div className="input-group-text bg-transparent">
//                   <span className="fa fa-search fs-10 text-600" />
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//         <div className="table-responsive scrollbar">
          
//           <table className="table table-sm table-striped fs-10 mb-0 overflow-hidden">
//             <thead className="bg-200">
//               <tr>
//                 <th
//                   className="text-900 sort pe-1 align-middle white-space-nowrap"
//                   data-sort="InvestmentID"
//                 >
//                   ID
//                 </th>
//                 <th
//                   className="text-900 sort pe-1 align-middle white-space-nowrap"
//                   data-sort="UserID"
//                 >
//                   User ID
//                 </th>
//                 <th
//                   className="text-900 sort align-middle white-space-nowrap text-start pe-4"
//                   data-sort="InvestmentName"
//                 >
                  
//                   Name
//                 </th>
//                 <th
//                   className="text-900 sort align-middle white-space-nowrap text-start pe-4"
//                   data-sort="InvestmentValue"
//                 >
                  
//                   Value
//                 </th>
//                 <th
//                   className="text-900 sort align-middle white-space-nowrap text-start pe-4"
//                   data-sort="PerformanceReturn"
//                 >
                  
//                   Performance &amp; Return
//                 </th>
//                 <th
//                   className="text-900 sort align-middle white-space-nowrap text-start pe-4"
//                   data-sort="InvestmentTerm"
//                 >
                  
//                   Term
//                 </th>
//                 <th
//                   className="text-900 sort align-middle white-space-nowrap text-start pe-4"
//                   data-sort="payment"
//                 >
//                   Action
//                 </th>
//               </tr>
//             </thead>
//             <tbody className="list" id="table-purchase-body">
//             {data.map((investment) => (
                
//               <tr className="btn-reveal-trigger">

// <>
//                 <th className="align-middle white-space-nowrap text-start name">
//                   <a href="#">{investment.ID}</a>
//                 </th>
//                 <td className="align-middle white-space-nowrap text-start email">
//                 {investment.user_id}
//                 </td>
//                 <td className="align-middle white-space-nowrap text-start email">
//                 {investment.Name}
//                 </td>
//                 <td className="align-middle white-space-nowrap text-start email">
//                 {investment.value}
//                 </td>
//                 <td className="align-middle white-space-nowrap text-start email">
//                 {investment.p}
//                 </td>
//                 <td className="align-middle white-space-nowrap text-start email">
//                 {investment.Tearm}
//                 </td>
//                 <td>
//                   <div className="d-flex justify-content-center align-items-center">
//                     <button className="btn-sm btn btn-primary">View</button>
//                     {/* <button className="btn-sm btn btn-danger">Download</button> */}
//                   </div>
//                 </td>
//                 </>
//               </tr>
//               ))}
            
//             </tbody>
//           </table>
//         </div>
//         <div className="row align-items-center mt-3">
//           <div className="pagination d-none" />
//           <div className="col">
//             <p className="mb-0 fs-10">
//               <span
//                 className="d-none d-sm-inline-block"
//                 data-list-info="data-list-info"
//               />
//               <span className="d-none d-sm-inline-block"> —</span>
//               <a className="fw-semi-bold" href="#!" data-list-view="*">
//                 View all
//                 <span
//                   className="fas fa-angle-right ms-1"
//                   data-fa-transform="down-1"
//                 />
//               </a>
//               <a className="fw-semi-bold d-none" href="#!" data-list-view="less">
//                 View Less
//                 <span
//                   className="fas fa-angle-right ms-1"
//                   data-fa-transform="down-1"
//                 />
//               </a>
//             </p>
//           </div>
//           <div className="col-auto d-flex">
//             <button
//               className="btn btn-sm btn-primary"
//               type="button"
//               data-list-pagination="prev"
//             >
//               <span>Previous</span>
//             </button>
//             <button
//               className="btn btn-sm btn-primary px-4 ms-2"
//               type="button"
//               data-list-pagination="next"
//             >
//               <span>Next</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
  
//   )
// }
