import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import userprofile from "../assets/img/team/avatar.png";
import { FaEdit } from "react-icons/fa";

const Profile = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [profileinfo, setProfileinfo] = useState();
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fileimage, setFileimage] = useState();
  const [fullname, setFullname] = useState();
  const [BaseUrl, setBaseUrl] = useState();
  const broker_id = localStorage.getItem("broker_id");
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    address: "",
    phone: "",
    altername_phone: "",
    joining_date: "",
    profile: null,
  });
  const [errors, setErrors] = useState({});
  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("broker_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-customer-Profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data.data;
      const baseurl = response.data.image_url;
      setBaseUrl(baseurl)
      console.warn(baseurl);
      localStorage.setItem('baseurl',baseurl);
      setFormData(data[0]);
      localStorage.setItem("profileimage", data[0].profile);
      setLoading(false);
      console.warn("User Profile Data:", data);
      setFileimage(null);
      const updatedFullName = `${data[0].first_name} ${data[0].middle_name !== "null" ? data[0].middle_name : ''} ${data[0].last_name}`;
      setFullname(updatedFullName);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(e.target.files[0]));
    console.warn(fileimage);
    setNewImage(file);
    setFormData({
      ...formData,
      profile: null,
    });
  };

  const validateName = (value) => {
    if (!value || !value.trim()) {
      return "This field is required";
    }
    return "";
  };

  const validateEmail = (value) => {
    if (!value.trim()) {
      return "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      return "Email address is invalid";
    }
    return "";
  };

  const validatePhone = (value) => {
    if (!value.trim()) {
      return "Phone number is required";
    } else if (!/^\d{10}$/.test(value)) {
      return "Phone number must be exactly 10 digits";
    }
    return "";
  };

  const validatealternativePhone = (value) => {
    if (!value.trim()) {
      return "Alternative Phone Number is required";
    } else if (!/^\d{10}$/.test(value)) {
      return "Alternative Phone Number must be exactly 10 digits";
    }
    return "";
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    for (const field in formData) {
      switch (field) {
        case "first_name":
        // case "middle_name":
        case "last_name":
          newErrors[field] = validateName(formData[field]);
          break;
        case "email":
          newErrors[field] = validateEmail(formData[field]);
          break;
        case "phone":
          newErrors[field] = validatePhone(formData[field]);
          break;
        case "altername_phone":
          newErrors[field] = validatealternativePhone(formData[field]);
          break;
        case "address":
          newErrors[field] = validateName(formData[field]);
          break;
        default:
          break;
      }
      if (newErrors[field]) {
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const token = localStorage.getItem("bear_token");

      let formDataToUpdate = new FormData();
      formDataToUpdate.append("first_name", formData.first_name);
      formDataToUpdate.append("middle_name", formData.middle_name);
      formDataToUpdate.append("last_name", formData.last_name);
      formDataToUpdate.append("email", formData.email);
      formDataToUpdate.append("address", formData.address);
      formDataToUpdate.append("broker_id", broker_id);
      formDataToUpdate.append("phone", formData.phone);
      formDataToUpdate.append("altername_phone", formData.altername_phone);
      formDataToUpdate.append("joining_date", formData.joining_date);
      formDataToUpdate.append("status", "1");
      formDataToUpdate.append("profile", newImage);
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}update-customer-Profile`,
        formDataToUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = response.data.status;
      getProfile();
      if (data === 1) {
        const MySwal = withReactContent(Swal);

        Swal.fire({
          title: "Success",
          text: "Profile Updated Successfully!!",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        });
      }
      const updatedFullName = `${formData.first_name} ${formData.middle_name !== "null" ? formData.middle_name : ''} ${formData.last_name}`;
      setFullname(updatedFullName);
      console.warn("User Profile Data:", data);
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  if (loading) {
    return (
      <div>
        <div id="preloader">
          <div id="loading-center">
            <div className="loader">
              <div className="waviy">
                <img
                  src="https://swaninvestment.in/frontant/assets/img/loader.png"
                  alt="Loader image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="page">
      <form onSubmit={handleSubmit} className="">
        <div className="d-flex justify-content-start align-items-start">
          <div className="card width_30">
            <div className="card-header card-height">
              <div className="avatarprofile avatar_image_width d-flex justify-content-center mx-auto">
                <img
                  className="rounded-circle img-thumbnail shadow-sm mx-auto"
                  id="profileimage"
                  src={
                    formData.profile
                      ? ` ${BaseUrl}/${formData.profile}`
                      : fileimage
                  }
                  width={150}
                  height={150}
                  alt=""
                />
                <label htmlFor="profileimage" className="custom-file-upload">
                  <FaEdit className="edit-icon" />
                  <input
                    id="profileimage"
                    type="file"
                    name="profile"
                    onChange={handleImageChange}
                    style={{
                      position: "absolute",
                      opacity: 0,
                      top: 0,
                      left: 0,
                      cursor: "pointer",
                    }}
                  />
                </label>
              </div>

              <h4 className="mb-1 editable-cell">
                <div className="editable-field mt-2">
                  <h5  className="editable-input w-100 text-center">{fullname}</h5>
                </div> 
              </h4>
            </div>
          </div>

          <div
            id="Profile"
            className="tabcontent"
            style={{ display: activeTab === 1 ? "block" : "none" }}
          >
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Profile</h3>
                </div>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="email">
                    First Name
                    </label>
                    <input
                     className="form-control"
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                  {errors.first_name && (
                    <div className="text-danger">{errors.first_name}</div>
                  )}
                  </div>
                  {formData.middle_name == "null" ? 
                <></>
                  :  <div className="col-lg-6">
                  <label className="form-label" htmlFor="phone">
                  Middle Name
                  </label>
                  <input
                  className="form-control"
                  type="text"
                  name="middle_name"
                  value={formData.middle_name}
                  onChange={handleChange}
                />
                {errors.middle_name && (
                  <div className="text-danger">{errors.middle_name}</div>
                )}
                </div>}
                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="phone">
                    Last Name
                    </label>
                    <input
                    className="form-control"
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                  {errors.last_name && (
                    <div className="text-danger">{errors.last_name}</div>
                  )}
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="phone">
                      Phone
                    </label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <div className="text-danger">{errors.phone}</div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="altername_phone">
                      Alternative Phone
                    </label>
                    <input
                      className="form-control"
                      id="altername_phone"
                      type="text"
                      name="altername_phone"
                      value={formData.altername_phone}
                      onChange={handleChange}
                    />
                    {errors.altername_phone && (
                      <div className="text-danger">{errors.altername_phone}</div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="address">
                      Address
                    </label>
                    <input
                      className="form-control"
                      id="address"
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                    {errors.address && (
                      <div className="text-danger">{errors.address}</div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="joining_date">
                      Joining Date
                    </label>
                    <input
                      className="form-control"
                      id="joining_date"
                      // type="date"
                      name="joining_date"
                      value={formData.joining_date}
                      onChange={handleChange}
                      disabled
                    />
                  </div>

                  <div className="col-lg-12 text-end">
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Add other tab contents here as needed */}
        </div>
      </form>
    </div>
  );
};

export default Profile;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import userprofile from "../assets/img/team/avatar.png";
// import { FaEdit } from "react-icons/fa";
// const Profile = () => {
//   const [activeTab, setActiveTab] = useState(1);
//   const [profileinfo, setProfileinfo] = useState();
//   const handleTabClick = (tabIndex) => {
//     setActiveTab(tabIndex);
//   };  const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [fileimage, setFileimage] = useState();
//   const broker_id = localStorage.getItem("broker_id");
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     address: "",
//     phone: "",
//     altername_phone: "",
//     joining_date: "",
//     profile: null,
//     // password: "", // Added password field
//   });
//   const [errors, setErrors] = useState({});
//   const [newImage, setNewImage] = useState(null);

//   useEffect(() => {
//     getProfile();
//   }, []);

//   const getProfile = async () => {
//     try {
//       const formData = new FormData();
//       formData.append("broker_id", broker_id);
//       const token = localStorage.getItem("bear_token");
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}show-customer-Profile`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       const data = response.data.data;
//       setFormData(data[0]);
//       localStorage.setItem("profileimage",data[0].profile)
//       setLoading(false);
//       console.warn("User Profile Data:", data);
//       setFileimage(null);
//     } catch (error) {
//       console.error("Error fetching user profile:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // const handleImageChange = (e) => {
//   //   const file = e.target.files[0];
//   //   setNewImage(file);
//   // };
//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     setFileimage(URL.createObjectURL(e.target.files[0]));
//     console.warn(fileimage)
//     setNewImage(file);
//     setFormData({
//       ...formData,
//       profile: null,
//     });
//   };


//   // const validateName = (value) => {
//   //   if (!value.trim()) {
//   //     return "Name is required";
//   //   }
//   //   return "";
//   // };
//   const validateName = (value) => {
//     if (!value || !value.trim()) {
//       return "Name is required";
//     }
//     return "";
//   };
//   const validateAddress = (value) => {
//     if (!value || !value.trim()) {
//       return "Address is required";
//     }
//     return "";
//   };

//   const validateEmail = (value) => {
//     if (!value.trim()) {
//       return "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(value)) {
//       return "Email address is invalid";
//     }
//     return "";
//   };

//   const validatePhone = (value) => {
//     if (!value.trim()) {
//       return "Phone number is required";
//     } else if (!/^\d{10}$/.test(value)) {
//       return "Phone number must be exactly 10 digits";
//     }
//     return "";
//   };
//   const validatealternativePhone = (value) => {
//     if (!value.trim()) {
//       return "Alternative Phone Number is required";
//     } else if (!/^\d{10}$/.test(value)) {
//       return "Alternative Phone Number must be exactly 10 digits";
//     }
//     return "";
//   };
  

//   const validateForm = () => {
//     const newErrors = {};
//     let isValid = true;

//     for (const field in formData) {
//       switch (field) {
//         case "name":
//           newErrors[field] = validateName(formData[field]);
//           break;
//         case "email":
//           newErrors[field] = validateEmail(formData[field]);
//           break;
//         case "phone":
//           newErrors[field] = validatePhone(formData[field]);
//           break;
//         case "altername_phone":
//           newErrors[field] = validatealternativePhone(formData[field]);
//           break;
//         case "address":
//           newErrors[field] = validateAddress(formData[field]);
//           break;
//         default:
//           break;
//       }
//       if (newErrors[field]) {
//         isValid = false;
//       }
//     }

//     setErrors(newErrors);
//     return isValid;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!validateForm()) return;

//     try {
//       const token = localStorage.getItem("bear_token");

//       let formDataToUpdate = new FormData();
//       formDataToUpdate.append("name", formData.name);
//       formDataToUpdate.append("email", formData.email);
//       formDataToUpdate.append("address", formData.address);
//       formDataToUpdate.append("broker_id", broker_id);
//       formDataToUpdate.append("phone", formData.phone);
//       formDataToUpdate.append("altername_phone", formData.altername_phone);
//       formDataToUpdate.append("joining_date", formData.joining_date);
//       formDataToUpdate.append("status", "1");
//       formDataToUpdate.append("profile", newImage);
//       // if (newImage) {
//       // }

//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}update-customer-Profile`,
//         formDataToUpdate,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       const data = response.data.status;
//       getProfile();
//       if (data === 1) {
//         const MySwal = withReactContent(Swal);
        
//         Swal.fire({
//           title: 'Success',
//           text: 'Profile Updated Successfully!!',
//           icon: 'success',
//           timer: 2000, 
//           showConfirmButton: true 
//       });
//       }
//       console.warn("User Profile Data:", data);
//     } catch (error) {
//       console.error("Error updating user profile:", error);
//     }
//   };
//   if (loading) {
//     return <div>
//        <div id="preloader">
//   <div id="loading-center">
//     <div className="loader">
//       <div className="waviy">
//         <img
//           src="https://swaninvestment.in/frontant/assets/img/loader.png"
//           alt="Loader image"
//         />
//       </div>
//     </div>
//   </div>
// </div>
//     </div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }
//   return (
//     <div className="page">
//       <form  onSubmit={handleSubmit} className="">
//         <div className="d-flex justify-content-start align-items-start">
//           <div className="card width_30">
//             <div className="card-header card-height">
//               <div className="avatarprofile avatar_image_width d-flex justify-content-center mx-auto">
//                 {/* <img
//                   className="rounded-circle img-thumbnail shadow-sm mx-auto"
//                   // src={userprofile}
//                   id="profile"
//                   src={fileimage}
//                   width={150} 
//                   height={150}
//                   alt=""
//                 /> */}
//                 <img
//                   className="rounded-circle img-thumbnail shadow-sm mx-auto"
//                   // src={userprofile}
//                   id="profile"
//                   src={
//                     formData.profile
//                       ? `http://192.168.1.22:5000/backend/uploads/customer/${formData.profile}`
//                       : fileimage
//                   }
               
//                   width={150} 
//                   height={150}
//                   alt=""
//                 />
//                    <label htmlFor="profile" className="custom-file-upload ">
//                       <FaEdit className="edit-icon" />
//                       <input
//                         id="profile"
//                         type="file"
//                         name="profile"
//                         onChange={handleImageChange}
//                         style={{
//                           position: "absolute",
//                           opacity: 0,
//                           top: 0,
//                           left: 0,
//                           cursor: "pointer",
//                         }}
//                       />
//                     </label>
//               </div>
             
//          <h4 className="mb-1  editable-cell">
//                 <div className="editable-field">
//                 <label className="form-label" htmlFor="email">
//                     User Name
//                     </label>
//                   <input
//                     className="editable-input w-100 text-center"
//                     type="text"
//                     name="name"
//                     value={formData.name}
//                     onChange={handleChange}

//                   />
//                   {/* <FaEdit className="edit-button" /> */}
//                   {errors.name && (
//                       <div className="text-danger">{errors.name}</div>
//                     )}
//                   {/* <i className="fa fa-pencil-square-o edit-button" /> */}
//                 </div>

//                 {/* <input type="text" value="Anthony Hopkins"/> */}

//                 {/* {profileinfo && profileinfo.name} */}
//                 <span
//                   data-bs-toggle="tooltip"
//                   data-bs-placement="right"
//                   title="Verified"
//                 >
//                   <small
//                     className="fa fa-check-circle text-primary"
//                     data-fa-transform="shrink-4 down-2"
//                   />
//                 </span>
//               </h4>
//               <h6 className="mb-1 text-center editable-cell">Worker</h6>
         
//             {/* <div className="tab"> */}
//               {/* <button
//                 // className={activeTab === 1 ? "" : "tablinks"}
//                 // onClick={() => handleTabClick(1)}
//                 // id="defaultOpen"
//               > */}
//                 {/* Profile */}
//               {/* </button> */}
//               {/* <button
//                 className={activeTab === 2 ? "tablinks active" : "tablinks"}
//                 // onClick={() => handleTabClick(2)}
//               >
//                 Professional Details
//               </button> */}
//               {/* <button
//                 className={activeTab === 3 ? "tablinks active" : "tablinks"}
//                 // onClick={() => handleTabClick(3)}
//               >
//                 Contact Details
//               </button> */}
//             {/* </div> */}
//             </div>
//           </div>
//           <div id="Profile" className="tabcontent" style={{ display: activeTab === 1 ? "block" : "none" }}>
//             <div className="card">
//               <div className="card-header">
//                 <div className="d-flex justify-content-between align-items-center">
//                   <h3 className="mb-0 "> Profile</h3>
//                   {/* <button
//                     className="btn btn-falcon-primary btn-sm px-3"
//                     type="button"
//                   >
//                     edit
//                   </button> */}
//                 </div>
//               </div>
//               <div className="card-body">
//                 <div className="row g-3">
//                   <div className="col-lg-6">
//                   <label className="form-label" htmlFor="email">
//                       Email
//                     </label>
//                     <input
//                       className="form-control"
//                       id="email"
//                       type="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       disabled
//                     />
//                     {errors.email && (
//                       <div className="text-danger">{errors.email}</div>
//                     )}
//                   </div>
//                   {/* <div className="col-lg-6">
//                     <label className="form-label" htmlFor="PersonalEmail">
//                       Personal Email :
//                     </label>
//                     <input
//                       className="form-control"
//                       id="PersonalEmail"
//                       type="Email"
//                       defaultValue="goodguy@gmail.com"
//                       disabled=""
//                     />
//                   </div> */}
//                   <div className="col-lg-6">
//                   <label className="form-label" htmlFor="phonenumber">
//                     Phone Number
//                   </label>
//                   <input
//                     className="form-control"
//                     id="phonenumber"
//                     type="text"
//                     name="phone"
//                     value={formData.phone}
//                     onChange={handleChange}
//                   />
//                   {errors.phone && (
//                     <div className="text-danger">{errors.phone}</div>
//                   )}
//                   </div>
             
//                   <div className="col-lg-6">
//                   <label className="form-label" htmlFor="apn">
//                     Alternative Phone Number
//                   </label>
//                   <input
//                     className="form-control"
//                     id="apn"
//                     type="text"
//                     name="altername_phone"
//                     value={formData.altername_phone}
//                     onChange={handleChange}
//                   />
//                    {errors.altername_phone && (
//                     <div className="text-danger">{errors.altername_phone}</div>
//                   )}
//                   </div>
//                   <div className="col-lg-6">
//                   <label className="form-label" htmlFor="address">
//                     Address
//                   </label>
//                   <input
//                     className="form-control"
//                     id="address"
//                     type="text"
//                     name="address"
//                     value={formData.address}
//                     onChange={handleChange}
//                   />
//                   {errors.address && (
//                     <div className="text-danger">{errors.address}</div>
//                   )}
//                   </div>
                
//                   <div className="col-lg-6">
//                   <label className="form-label" htmlFor="jd">
//                     Joining Dateff
//                   </label>
//                   <input
//                     className="form-control"
//                     id="jd"
//                     // type="date"
//                     name="joining_date"
//                     value={formData.joining_date}
//                     onChange={handleChange}
//                     disabled
//                   />
//                   </div>
//                   {/* <div className="col-lg-6">
//                     <label className="form-label" htmlFor="Mobile">
//                       Mobile No :
//                     </label>
//                     <input
//                       className="form-control"
//                       id="Mobile"
//                       type="number"
//                       defaultValue={9865471230}
//                       disabled=""
//                     />
//                   </div> */}
//                   <div className="col-lg-12 d-flex justify-content-end">
//                   <button className="btn btn-primary" type="submit">
//                     Update{" "}
//                   </button>
//                   {/* <button className="btn btn-primary mx-1">Log Out </button> */}
//                 </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div id="Professional" className="tabcontent" style={{ display: activeTab === 2 ? "block" : "none" }}>
//             <div className="card">
//               <div className="card-header">
//                 <div className="d-flex justify-content-between align-items-center">
//                   <h3 className="mb-0 "> Professional Details</h3>
//                   <button
//                     className="btn btn-falcon-primary btn-sm px-3"
//                     type="button"
//                   >
//                     edit
//                   </button>
//                 </div>
//               </div>
//               <div className="card-body bg-body-tertiary">
//                 <form className="row g-3">
//                   <div className="col-lg-6">
//                     {" "}
//                     <label className="form-label" htmlFor="Joining">
//                       Joining Date
//                     </label>
//                     <input
//                       className="form-control"
//                       id="Joining"
//                       type="text"
//                       defaultValue={10102024}
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-lg-6">
//                     {" "}
//                     <label className="form-label" htmlFor="Experience">
//                       Total Experience
//                     </label>
//                     <input
//                       className="form-control"
//                       id="Experience"
//                       type="text"
//                       defaultValue="6 years"
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-lg-6">
//                     {" "}
//                     <label className="form-label" htmlFor="Confirmation">
//                       Confirmation Date Number
//                     </label>
//                     <input
//                       className="form-control"
//                       id="Confirmation"
//                       type="text"
//                       defaultValue={12052024}
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-lg-6">
//                     {" "}
//                     <label className="form-label" htmlFor="PreviousExperience">
//                       Previous Work Experience (Optional)
//                     </label>
//                     <input
//                       className="form-control"
//                       id="PreviousExperience"
//                       type="text"
//                       defaultValue="9 year"
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-12 d-flex justify-content-end">
//                     <button className="btn btn-primary" type="submit">
//                       Update{" "}
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//           <div id="Contact" className="tabcontent" style={{ display: activeTab === 3 ? "block" : "none" }}>
//             <div className="card">
//               <div className="card-header">
//                 <div className="d-flex justify-content-between align-items-center">
//                   <h3 className="mb-0 "> Contact Details</h3>
//                   <button
//                     className="btn btn-falcon-primary btn-sm px-3"
//                     type="button"
//                   >
//                     edit
//                   </button>
//                 </div>
//               </div>
//               <div className="card-body bg-body-tertiary">
//                 <form className="row g-3">
//                   <div className="col-lg-6">
//                     {" "}
//                     <label
//                       className="form-label"
//                       htmlFor="personalmobilenumber"
//                     >
//                       Alternative Mobile Number (Optional)
//                     </label>
//                     <input
//                       className="form-control"
//                       id="personalmobilenumber"
//                       type="text"
//                       defaultValue={9874563210}
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-lg-6">
//                     {" "}
//                     <label
//                       className="form-label"
//                       htmlFor="Alternativemobilenumber"
//                     >
//                       Alternative Mobile Number
//                     </label>
//                     <input
//                       className="form-control"
//                       id="Alternativemobilenumber"
//                       type="text"
//                       defaultValue={9874563210}
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-lg-6">
//                     {" "}
//                     <label className="form-label" htmlFor="Emergency">
//                       Emergency Mobile Number
//                     </label>
//                     <input
//                       className="form-control"
//                       id="Emergency"
//                       type="text"
//                       defaultValue={9874563210}
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-lg-6">
//                     {" "}
//                     <label className="form-label" htmlFor="Address">
//                       Current Address
//                     </label>
//                     <input
//                       className="form-control"
//                       id="Address"
//                       type="text"
//                       defaultValue="3989 Rolando Valley"
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-lg-6">
//                     {" "}
//                     <label className="form-label" htmlFor="PermanentAddress">
//                       Permanent Address
//                     </label>
//                     <input
//                       className="form-control"
//                       id="PermanentAddress"
//                       type="text"
//                       defaultValue="3989 Rolando Valley"
//                       disabled=""
//                     />
//                   </div>
//                   <div className="col-12 d-flex justify-content-end">
//                     <button className="btn btn-primary" type="submit">
//                       Update{" "}
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };
// export default Profile;
