import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GoogleLogin } from 'react-google-login';
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
export default function Forgetpassword() {

  const [loading, setLoading] = useState(false);
  const [email, setemail] = useState('');

  const navigate = useNavigate();

  const onInputChangeemail = (e) => {
    setemail(e.target.value);
  };

  const Submit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      if (!email) {
        toast.error("Email is missing!");
        setLoading(false);
        return;
      }

      let url = `${process.env.REACT_APP_API_URL}check-customer-email-existance`;

      const formData = new FormData();
      formData.append("email", email);

      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config).then(function (response) {

        if (response.data.status == 1) {
          console.warn(response.data.success);
          toast.success(response.data.message);
          setTimeout(() => {
            navigate('/Changepasssword');
          }, 1000);
        
           
        } else {
          toast.error(response.data.message);
        }
      })
        .finally(() => {
          // setTimeout(() =>{
          setLoading(false);
          // },5000);
        });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }

  };

  return (
    <div>
      <main className="main" id="top">
        <div className="container-fluid">
          <div className="row min-vh-100 flex-center g-0">
            <div className="col-lg-8 col-xxl-5 py-3 position-relative">
              <img
                className="bg-auth-circle-shape"
                src="assets/img/icons/spot-illustrations/bg-shape.png"
                alt=""
                width={250}
              />
              <img
                className="bg-auth-circle-shape-2"
                src="assets/img/icons/spot-illustrations/shape-1.png"
                alt=""
                width={150}
              />
              <div className="card overflow-hidden z-1">
                <div className="card-body p-0">
                  <div className="row g-0 h-100">
                    <div className="col-md-5 text-center bg-card-gradient">
                      <div
                        className="position-relative p-4 pt-md-5 pb-md-7"
                        data-bs-theme="light"
                      >
                        <div
                          className="bg-holder bg-auth-card-shape"
                          style={{
                            backgroundImage:
                              "url(assets/img/icons/spot-illustrations/half-circle.png)"
                          }}
                        />
                        {/*/.bg-holder*/}
                        <div className="z-1 position-relative">
                          <a
                            className="link-light mb-4 font-sans-serif fs-5 d-inline-block fw-bolder"
                            href="index.php"
                          >
                            Swan
                          </a>
                          <p className="opacity-75 text-white">
                            With the power of Swan, you can now focus only on
                            functionaries for your digital products, while leaving the
                            UI design on us!
                          </p>
                        </div>
                      </div>
                      <div
                        className="mt-3 mb-4 mt-md-4 mb-md-5"
                        data-bs-theme="light"
                      >
                        <p className="mb-0 mt-4 mt-md-5 fs-10 fw-semi-bold text-white opacity-75">
                          Read our{" "}
                          <a
                            className="text-decoration-underline text-white"
                            href="#!"
                          >
                            terms
                          </a>{" "}
                          and{" "}
                          <a
                            className="text-decoration-underline text-white"
                            href="#!"
                          >
                            conditions{" "}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-7 d-flex flex-center">
                      <div className="p-4 p-md-5 flex-grow-1">
                        <div className="text-center text-md-start">
                          <h4 className="mb-0"> Forgot your password?</h4>
                          <p className="mb-4">
                            Enter your email and we'll send you a reset link.
                          </p>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-sm-8 col-md">
                            <form className="mb-3">
                              <input
                                value={email}
                                onChange={onInputChangeemail}
                                className="form-control"
                                type="email"
                                placeholder="Email address"
                                required=""
                              />
                              <div className="mb-3" />
                              <button
                               disabled={loading}
                               onClick={Submit}
                                // to="/Changepasssword"
                                onclick="showAlert()"
                                className="btn btn-primary d-block w-100 mt-3"
                              >
                                Send OTP
                                {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                              </button>
                            </form>
                            <a className="fs-10 text-600" href="#!">
                              I can't recover my account using this page
                              <span className="d-inline-block ms-1">→</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
<ToastContainer/>

    </div>
  )
}
