import React from 'react'
import {Link} from 'react-router-dom'
export default function Contactus() {
  return (
    <div className="container-fluid">
    <div className="card mb-3">
      <div
        className="bg-holder d-none d-lg-block bg-card"
        style={{
          backgroundImage:
            "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
        }}
      />
      {/*/.bg-holder*/}
      <div className="card-body position-relative">
        <div className="row">
          <div className="col-lg-8">
            <h3 className="mb-0">Contact us</h3>
          </div>
        </div>
      </div>
    </div>
    <div className="card mb-3">
      <div className="card-body bg-body-tertiary">
        <div className="tab-content">
          <div
            className="tab-pane preview-tab-pane active show"
            role="tabpanel"
            aria-labelledby="tab-dom-490c6195-a482-4484-90ab-f5c0e8bddd9f"
            id="dom-490c6195-a482-4484-90ab-f5c0e8bddd9f"
          >
            <form className="row g-3 needs-validation" noValidate="">
              <div className="col-md-6">
                <label className="form-label" htmlFor="validationCustom01">
                  First name
                </label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="text"
                  defaultValue=""
                  required=""
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="email">
                  Email ID
                </label>
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  defaultValue=""
                  required=""
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="tel">
                  Phone Number
                </label>
                <input
                  className="form-control"
                  id="tel"
                  type="tel"
                  defaultValue=""
                  required=""
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="SubjectLine">
                  Subject Line
                </label>
                <input
                  className="form-control"
                  id="SubjectLine"
                  type="text"
                  required=""
                />
                <div className="invalid-feedback">
                  Please provide a valid city.
                </div>
              </div>
              <div className="col-md-12">
                <label className="form-label" htmlFor="Message">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="Message"
                  type="text"
                  required=""
                  defaultValue={""}
                />
                <div className="invalid-feedback">
                  Please provide a valid city.
                </div>
              </div>
              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="invalidCheck"
                    type="checkbox"
                    defaultValue=""
                    required=""
                  />
                  <label className="form-check-label mb-0" htmlFor="invalidCheck">
                    Agree to <Link to={'/Termsandcondition'}>terms</Link> and  <Link to={'/Termsandcondition'}>conditions</Link>
                  </label>
                  <div className="invalid-feedback mt-0">
                    You must agree before submitting.
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}
