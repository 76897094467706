import React from 'react';
import userprofile from "../assets/img/team/2.jpg";
const Home = () => {
return (
    <div className="page">
     <>
  <div className="row mb-3">
    <div className="col-md-12">
      <div className="card bg-100 shadow-none border">
        <div className="row gx-0 flex-between-center">
          <div className="col-sm-auto d-flex align-items-center">
            <img
              className="ms-n2"
              src="./assets/img/illustrations/crm-bar-chart.png"
              alt=""
              width={90}
            />
            <div>
              <h6 className="text-primary fs-10 mb-0">Welcome to </h6>
              <h4 className="text-primary fw-bold mb-0">
                Swan <span className="text-info fw-medium">Investment</span>
              </h4>
            </div>
            <img
              className="ms-n4 d-md-none d-lg-block"
              src="./assets/img/illustrations/crm-line-chart.png"
              alt=""
              width={150}
            />
          </div>
          <div className="col-md-auto p-3">
            <form className="row align-items-center g-3">
              <div className="col-auto">
                <h6 className="text-700 mb-0">Showing Data For: </h6>
              </div>
              <div className="col-md-auto position-relative">
                <input
                  className="form-control form-control-sm datetimepicker ps-4"
                  id=""
                  type="text"
                  data-options='{"mode":"range","dateFormat":"M d","disableMobile":true , "defaultDate": ["Feb 20", "Feb 27"] }'
                />
                <span className="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2">
                  {" "}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row g-3 mb-3">
    <div className="col-md-6 col-xxl-3">
      <div className="card h-md-100 ecommerce-card-min-width">
        <div className="card-header pb-0">
          <h6 className="mb-0 mt-2 d-flex align-items-center">
            Yearly Client
            <span
              className="ms-1 text-400"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Calculated according to last week's sales"
            >
              <span
                className="far fa-question-circle"
                data-fa-transform="shrink-1"
              />
            </span>
          </h6>
        </div>
        <div className="card-body d-flex flex-column justify-content-end">
          <div className="row">
            <div className="col">
              <p className="font-sans-serif lh-1 mb-1 fs-5">$47K</p>
              <span className="badge badge-subtle-success rounded-pill fs-11">
                +3.5%
              </span>
            </div>
            <div className="col-auto ps-0">
              <div className="echart-bar-weekly-sales h-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6 col-xxl-3">
      <div className="card h-md-100">
        <div className="card-header pb-0">
          <h6 className="mb-0 mt-2">Total Client</h6>
        </div>
        <div className="card-body d-flex flex-column justify-content-end">
          <div className="row justify-content-between">
            <div className="col-auto align-self-end">
              <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                58.4K
              </div>
              <span className="badge rounded-pill fs-11 bg-200 text-primary">
                <span className="fas fa-caret-up me-1" />
                13.6%
              </span>
            </div>
            <div className="col-auto ps-0 mt-n4">
              <div
                className="echart-default-total-order"
                data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                data-echart-responsive="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row g-0">
    <div className="col-lg-12">
      <div
        className="card"
        id="TableCrmRecentLeads"
        data-list='{"valueNames":["name","email","status"],"page":8,"pagination":true}'
      >
        <div className="card-header d-flex flex-between-center flex-wrap gap-2 py-2">
          <h6 className="mb-0">Recent Leads</h6>
          <div className="d-none" id="table-recent-leads-actions">
            <div className="d-flex">
              <select
                className="form-select form-select-sm"
                aria-label="Bulk actions"
              >
                <option selected="">Bulk actions</option>
                <option value="Delete">Delete</option>
                <option value="Archive">Archive</option>
              </select>
              <button
                className="btn btn-falcon-default btn-sm ms-2"
                type="button"
              >
                Apply
              </button>
            </div>
          </div>
          <div id="table-recent-leads-replace-element">
            <div className="dropdown font-sans-serif btn-reveal-trigger">
              <button
                className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal"
                type="button"
                id="recent-leads-header-dropdown"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="fas fa-ellipsis-h fs-11" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-end border py-2"
                aria-labelledby="recent-leads-header-dropdown"
              >
                <a className="dropdown-item" href="#!">
                  View
                </a>
                <a className="dropdown-item" href="#!">
                  Export
                </a>
                <div className="dropdown-divider" />
                <a className="dropdown-item text-danger" href="#!">
                  Remove
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-0 py-0">
          <div className="table-responsive scrollbar">
            <table className="table fs-10 mb-0">
              <thead className="bg-200">
                <tr>
                  <th
                    className="white-space-nowrap align-middle py-0"
                    style={{ maxWidth: 30 }}
                  >
                    <div className="form-check mb-0 d-flex align-items-center">
                      {/* <input
                        className="form-check-input mt-0"
                        id="checkbox-bulk-leads-select"
                        type="checkbox"
                        data-bulk-select='{"body":"table-recent-leads-body","actions":"table-recent-leads-actions","replacedElement":"table-recent-leads-replace-element"}'
                      /> */}
                    </div>
                  </th>
                  <th className="text-800 sort align-middle" data-sort="name">
                    Name
                  </th>
                  <th className="text-800 sort align-middle" data-sort="email">
                    Email and Phone
                  </th>
                  <th className="text-800 sort align-middle" data-sort="status">
                    Status
                  </th>
                  <th className="text-800 sort align-middle text-end">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="list" id="table-recent-leads-body">
                <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                  <td className="align-middle" style={{ maxWidth: 30 }}>
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="recent-leads-0"
                        data-bulk-select-row="data-bulk-select-row"
                      />
                    </div>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <a className="text-800" href="/profile">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl">
                          <img
                            className="rounded-circle"
                            src={userprofile}
                            alt=""
                          />
                        </div>
                        <h6 className="mb-0 ps-2 name">Kerry Ingram</h6>
                      </div>
                    </a>
                  </td>
                  <td className="align-middle white-space-nowrap text-primary email">
                    <a href="mailto:john@gmail.com">john@gmail.com</a>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <small className="badge fw-semi-bold rounded-pill status badge-subtle-primary">
                      {" "}
                      New Lead
                    </small>
                  </td>
                  <td className="align-middle white-space-nowrap text-end position-relative">
                  <div className="hover-actions bg-100">
                      <button className="btn icon-item rounded-3 bg-info me-2 fs-11 icon-item-sm">
                        <span className="far fa-edit" />
                      </button>
                      <button className="btn icon-item rounded-3 me-2 fs-11 icon-item-sm">
                        <span className="far fa-comment" />
                      </button>
                    </div>
                    <div className="dropdown font-sans-serif btn-reveal-trigger">
                      <button
                        className="btn btn-link text-600 btn-sm bg-danger dropdown-toggle dropdown-caret-none btn-reveal-sm transition-none"
                        type="button"
                        id="crm-recent-leads-2"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fs-11 " ></span>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end border py-2"
                        aria-labelledby="crm-recent-leads-2"
                      >
                        <a className="dropdown-item" href="#!">
                          View
                        </a>
                        <a className="dropdown-item" href="#!">
                          Export
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#!">
                          Remove
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                  <td className="align-middle" style={{ maxWidth: 30 }}>
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="recent-leads-1"
                        data-bulk-select-row="data-bulk-select-row"
                      />
                    </div>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <a className="text-800" href="/profile">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl">
                          <img
                            className="rounded-circle"
                            src={userprofile}
                            alt=""
                          />
                        </div>
                        <h6 className="mb-0 ps-2 name">Bradie Knowall</h6>
                      </div>
                    </a>
                  </td>
                  <td className="align-middle white-space-nowrap text-primary email">
                    <a href="mailto:bradie@mail.ru">bradie@mail.ru</a>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <small className="badge fw-semi-bold rounded-pill status badge-subtle-primary">
                      {" "}
                      New Lead
                    </small>
                  </td>
                  <td className="align-middle white-space-nowrap text-end position-relative">
                  <div className="hover-actions bg-100">
                      <button className="btn icon-item rounded-3 bg-info me-2 fs-11 icon-item-sm">
                        <span className="far fa-edit" />
                      </button>
                      <button className="btn icon-item rounded-3 me-2 fs-11 icon-item-sm">
                        <span className="far fa-comment" />
                      </button>
                    </div>
                    <div className="dropdown font-sans-serif btn-reveal-trigger">
                      <button
                        className="btn btn-link text-600 btn-sm bg-danger dropdown-toggle dropdown-caret-none btn-reveal-sm transition-none"
                        type="button"
                        id="crm-recent-leads-2"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fs-11 " ></span>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end border py-2"
                        aria-labelledby="crm-recent-leads-2"
                      >
                        <a className="dropdown-item" href="#!">
                          View
                        </a>
                        <a className="dropdown-item" href="#!">
                          Export
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#!">
                          Remove
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                  <td className="align-middle" style={{ maxWidth: 30 }}>
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="recent-leads-2"
                        data-bulk-select-row="data-bulk-select-row"
                      />
                    </div>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <a className="text-800" href="/profile">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl">
                          <img
                            className="rounded-circle"
                            src={userprofile}
                            alt=""
                          />
                        </div>
                        <h6 className="mb-0 ps-2 name">Jenny Horas</h6>
                      </div>
                    </a>
                  </td>
                  <td className="align-middle white-space-nowrap text-primary email">
                    <a href="mailto:jenny@mail.ru">jenny@mail.ru</a>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <small className="badge fw-semi-bold rounded-pill status badge-subtle-warning">
                      {" "}
                      Cold Lead
                    </small>
                  </td>
                  <td className="align-middle white-space-nowrap text-end position-relative">
                    <div className="hover-actions bg-100">
                      <button className="btn icon-item rounded-3 bg-info me-2 fs-11 icon-item-sm">
                        <span className="far fa-edit" />
                      </button>
                      <button className="btn icon-item rounded-3 me-2 fs-11 icon-item-sm">
                        <span className="far fa-comment" />
                      </button>
                    </div>
                    <div className="dropdown font-sans-serif btn-reveal-trigger">
                      <button
                        className="btn btn-link text-600 btn-sm bg-danger dropdown-toggle dropdown-caret-none btn-reveal-sm transition-none"
                        type="button"
                        id="crm-recent-leads-2"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fs-11 " ></span>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end border py-2"
                        aria-labelledby="crm-recent-leads-2"
                      >
                        <a className="dropdown-item" href="#!">
                          View
                        </a>
                        <a className="dropdown-item" href="#!">
                          Export
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#!">
                          Remove
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                  <td className="align-middle" style={{ maxWidth: 30 }}>
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="recent-leads-3"
                        data-bulk-select-row="data-bulk-select-row"
                      />
                    </div>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <a className="text-800" href="/profile">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl">
                          <img
                            className="rounded-circle"
                            src={userprofile}
                            alt=""
                          />
                        </div>
                        <h6 className="mb-0 ps-2 name">Chris Pratt</h6>
                      </div>
                    </a>
                  </td>
                  <td className="align-middle white-space-nowrap text-primary email">
                    <a href="mailto:pratt@mail.ru">pratt@mail.ru</a>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <small className="badge fw-semi-bold rounded-pill status badge-subtle-warning">
                      {" "}
                      Cold Lead
                    </small>
                  </td>
                  <td className="align-middle white-space-nowrap text-end position-relative">
                  <div className="hover-actions bg-100">
                      <button className="btn icon-item rounded-3 bg-info me-2 fs-11 icon-item-sm">
                        <span className="far fa-edit" />
                      </button>
                      <button className="btn icon-item rounded-3 me-2 fs-11 icon-item-sm">
                        <span className="far fa-comment" />
                      </button>
                    </div>
                    <div className="dropdown font-sans-serif btn-reveal-trigger">
                      <button
                        className="btn btn-link text-600 btn-sm bg-danger dropdown-toggle dropdown-caret-none btn-reveal-sm transition-none"
                        type="button"
                        id="crm-recent-leads-2"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fs-11 " ></span>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end border py-2"
                        aria-labelledby="crm-recent-leads-2"
                      >
                        <a className="dropdown-item" href="#!">
                          View
                        </a>
                        <a className="dropdown-item" href="#!">
                          Export
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#!">
                          Remove
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                  <td className="align-middle" style={{ maxWidth: 30 }}>
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="recent-leads-4"
                        data-bulk-select-row="data-bulk-select-row"
                      />
                    </div>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <a className="text-800" href="/profile">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl">
                          <img
                            className="rounded-circle"
                            src={userprofile}
                            alt=""
                          />
                        </div>
                        <h6 className="mb-0 ps-2 name">Andy Murray</h6>
                      </div>
                    </a>
                  </td>
                  <td className="align-middle white-space-nowrap text-primary email">
                    <a href="mailto:andy@gmail.com">andy@gmail.com</a>
                  </td>
                  <td className="align-middle white-space-nowrap">
                    <small className="badge fw-semi-bold rounded-pill status badge-subtle-success">
                      {" "}
                      Won Lead
                    </small>
                  </td>
                  <td className="align-middle white-space-nowrap text-end position-relative">
                  <div className="hover-actions bg-100">
                      <button className="btn icon-item rounded-3 bg-info me-2 fs-11 icon-item-sm">
                        <span className="far fa-edit" />
                      </button>
                      <button className="btn icon-item rounded-3 me-2 fs-11 icon-item-sm">
                        <span className="far fa-comment" />
                      </button>
                    </div>
                    <div className="dropdown font-sans-serif btn-reveal-trigger">
                      <button
                        className="btn btn-link text-600 btn-sm bg-danger dropdown-toggle dropdown-caret-none btn-reveal-sm transition-none"
                        type="button"
                        id="crm-recent-leads-2"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fs-11 " ></span>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end border py-2"
                        aria-labelledby="crm-recent-leads-2"
                      >
                        <a className="dropdown-item" href="#!">
                          View
                        </a>
                        <a className="dropdown-item" href="#!">
                          Export
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#!">
                          Remove
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer bg-body-tertiary p-0">
          <div className="pagination d-none" />
          <a className="btn btn-sm btn-link d-block py-2" href="#!">
            Show full list
            <span className="fas fa-chevron-right ms-1 fs-11" />
          </a>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  );
};
export default Home;
