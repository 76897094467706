import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Faq() {
  const broker_id = localStorage.getItem("broker_id");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("broker_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}cus_faq-managements`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      // Logging response and setting data
      console.warn("Response Data:", response.data.data);

      // Check if response.data.data is an array before setting state
      if (Array.isArray(response.data.data)) {
        setData(response.data.data); // Set data state
        setLoading(false);
      } else {
        console.error(
          "Data received from API is not an array:",
          response.data.data
        );
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  if (loading) {
    return <div>
       <div id="preloader">
  <div id="loading-center">
    <div className="loader">
      <div className="waviy">
        <img
          src="https://swaninvestment.in/frontant/assets/img/loader.png"
          alt="Loader image"
        />
      </div>
    </div>
  </div>
</div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="card">
      <div className="card-body position-relative">
        <div className="row">
          <div className="col-lg-8">
            <h3>FAQ on using Swan</h3>
            <p className="mb-0">
              Below you'll find answers to the questions we get asked the most.
            </p>
          </div>
        </div>
      </div>
        <div className="card-body">
          <div
            className="accordion border rounded overflow-hidden"
            id="accordionFaq"
          >
            {data.map((item, index) => (
              <div
                className="card shadow-none rounded-bottom-0 border-bottom"
                key={index}
              >
                <div className="accordion-item border-0">
                  <div
                    className="card-header p-0"
                    id={`faqAccordionHeading${index}`}
                  >
                    <button
                      className="accordion-button btn btn-link text-decoration-none d-block w-100 py-2 px-3 collapsed border-0 text-start rounded-0 shadow-none"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseFaqAccordion${index}`}
                      aria-expanded="false"
                      aria-controls={`collapseFaqAccordion${index}`}
                    >
                      <span
                        className="fas fa-caret-right accordion-icon me-3"
                        data-fa-transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900">
                        {index + 1}. {item.title}
                      </span>
                    </button>
                  </div>
                  <div
                    className="accordion-collapse collapse"
                    id={`collapseFaqAccordion${index}`}
                    aria-labelledby={`faqAccordionHeading${index}`}
                    data-parent="#accordionFaq"
                  >
                    <div className="accordion-body p-0">
                      <div className="card-body pt-2">
                        <div className="ps-3 mb-0">
                          <p>{item.descriptions}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
