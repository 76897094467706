// sidebar.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { MdDashboard } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import { MdOutlinePersonalInjury } from "react-icons/md";

import Logo from "../assets/img/logos/swan.logo.png";
const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
      <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
        <div className="d-flex align-items-center">
          <div className="toggle-icon-wrapper">
            <button
              className="btn navbar-toggler-humburger-icon toggle-btn navbar-vertical-toggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              onClick={toggleSidebar}
              title="Toggle Navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line" />
              </span>
            </button>
          </div>
          <Link className="navbar-brand" to="/home">
            <div className="d-flex align-items-center py-3">
              <img className="me-2" src={Logo} alt="" width={130} />
              {/* <span class="font-sans-serif text-primary">falcon</span> */}
            </div>
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
          <div className="navbar-vertical-content scrollbar">
            <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
              <li className="nav-item">
                {/* parent pages*/}
                <Link className="nav-link dropdown-indicator" to="/Home">
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdDashboard />
                    </span>
                    <span className="nav-link-text ps-1">Dashboard</span>
                  </div>
                </Link>
              </li>

              <li className="nav-item">
                {/* parent pages*/}
                <a
                  className="nav-link dropdown-indicator"
                  href="#profile"
                  role="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="profile"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdOutlinePersonalInjury />
                    </span>
                    <span className="nav-link-text ps-1">Profile</span>
                  </div>
                </a>
                <ul className="nav collapse" id="profile">
                  <li className="nav-item">
                    <Link className="nav-link" to="/profile">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-text ps-1">
                          Personal Details
                        </span>
                      </div>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link " to="/Profile">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-text ps-1">
                          Employee Details
                        </span>
                      </div>
                    </Link>
                  </li> */}
                </ul>
              </li>
              

              {/* <ul class="nav collapse show" id="dashboard"> */}
              {/* <li className="nav-item">
                <a
                  className="nav-link dropdown-indicator"
                  href="#profile"

                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                    <FaUser />
                    </span>
                    <span className="nav-link-text ps-1">Profile</span>
                  </div>
                </a>
                <ul className="nav collapse" id="profile">
                  <li className="nav-item">
                    <Link className="nav-link" to="/Profile">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-text ps-1">
                          <span className="nav-link-icon">
                            <span className="fa-solid fa-circle-question" />
                          </span>
                          Employee Details
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="personaldetails.php">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-text ps-1">
                          
                          <span className="nav-link-icon">
                            <span className="fa-solid fa-circle-question" />
                          </span>
                          Personal Details
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
              </li> */}
              <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div className="col-auto navbar-vertical-label">Details</div>
                <div className="col ps-0">
                  <hr className="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <li className="nav-item">
                {/* parent pages*/}
                <a
                  className="nav-link dropdown-indicator"
                  href="#salary"
                  role="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="salary"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <IoDocumentText />
                    </span>
                    <span className="nav-link-text ps-1">Investment</span>
                  </div>
                </a>
                <ul className="nav collapse" id="salary">
                  <li className="nav-item">
                    <Link className="nav-link" to="/Totalinvestment">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-text ps-1">
                          
                          <span className="nav-link-icon">
                            <span className="fa-solid fa-circle-question" />
                          </span>
                          Total Investment
                        </span>
                      </div>
                    </Link>
                    {/* more inner pages*/}
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/Investmentview">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-text ps-1">
                          
                          <span className="nav-link-icon">
                            <span className="fa-solid fa-circle-question" />
                          </span>
                          My Investment
                        </span>
                      </div>
                    </Link>
                    {/* more inner pages*/}
                  </li>
                </ul>
              </li>

              <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div className="col-auto navbar-vertical-label">Pages</div>
                <div className="col ps-0">
                  <hr className="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              
              <li className="nav-item">
                <Link className="nav-link" to="/Aboutus">
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">
                      
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      About Us
                    </span>
                  </div>
                </Link>
                {/* more inner pages*/}
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Termsandcondition">
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">
                      
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Terms &amp; Conditions
                    </span>
                  </div>
                </Link>
                {/* more inner pages*/}
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Privacypolicy">
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">
                      
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Privacy Policy
                    </span>
                  </div>
                </Link>
                {/* more inner pages*/}
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Contactus">
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">
                      
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Contact Us
                    </span>
                  </div>
                </Link>
                {/* more inner pages*/}
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Faq">
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">
                      
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      FAQs
                    </span>
                  </div>
                </Link>
                {/* more inner pages*/}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* <ul>
        <li>
        <FontAwesomeIcon icon={faHome} /><Link to="/">Home</Link>
        </li>
        <li>
        <FontAwesomeIcon icon={faAddressBook} /> <Link to="/analytics">Analytics</Link>
        </li>
        <li>
         <FontAwesomeIcon icon={faTools} /> <Link to="/settings">Settings</Link>
        </li>
      </ul> */}
    </div>
  );
};

export default Sidebar;
