import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GoogleLogin } from 'react-google-login';
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

export default function Changepasssword() {
  const [loading, setLoading] = useState(false);
  const [otp, setotp] = useState('');
  const [password, setPassword] = useState('');
  const [con_password, setConPassword] = useState('');


  const navigate = useNavigate();
  const onInputChangeotp = (e) => {
    setotp(e.target.value.slice(0, 6));
  };
  const onInputChangepassword = (e) => {
    setPassword(e.target.value);
  };
  const onInputChangeconpassword = (e) => {
    setConPassword(e.target.value);
  };
  const Submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!otp) {
        toast.error("Otp is missing!");
        setLoading(false);
        return;
      }
      if (password.length < 6) {
        toast.error("Password must be at least 6 characters long!");
        setLoading(false);
        return;
      }
      if (password !== con_password) {
        toast.error("Passwords do not match!");
        setLoading(false);
        return;
    }

      let url = `${process.env.REACT_APP_API_URL}update-customer-forget-password`;

      const formData = new FormData();
      formData.append("otp", otp);
      formData.append("new_password", password);
      formData.append("repeat_password", con_password);

      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config).then(function (response) {

        if (response.data.status == 1) {
          console.warn(response.data.success);
          toast.success(response.data.message);
          setTimeout(() => {
            navigate('/');
          }, 1000);


        } else {
          toast.error(response.data.message);
        }
      })
        .finally(() => {
          // setTimeout(() =>{
          setLoading(false);
          // },5000);
        });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }

  };
  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="assets/img/icons/spot-illustrations/bg-shape.png"
              alt=""
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="assets/img/icons/spot-illustrations/shape-1.png"
              alt=""
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0 h-100">
                  <div className="col-md-5 text-center bg-card-gradient">
                    <div
                      className="position-relative p-4 pt-md-5 pb-md-7"
                      data-bs-theme="light"
                    >
                      <div
                        className="bg-holder bg-auth-card-shape"
                        style={{
                          backgroundImage:
                            "url(assets/img/icons/spot-illustrations/half-circle.png)"
                        }}
                      />
                      {/*/.bg-holder*/}
                      <div className="z-1 position-relative">
                        <a
                          className="link-light mb-4 font-sans-serif fs-5 d-inline-block fw-bolder"
                          href="index.php"
                        >
                          Swan
                        </a>
                        <p className="opacity-75 text-white">
                          With the power of Swan, you can now focus only on
                          functionaries for your digital products, while leaving the
                          UI design on us!
                        </p>
                      </div>
                    </div>
                    <div
                      className="mt-3 mb-4 mt-md-4 mb-md-5"
                      data-bs-theme="light"
                    >
                      <p className="mb-0 mt-4 mt-md-5 fs-10 fw-semi-bold text-white opacity-75">
                        Read our{" "}
                        <a
                          className="text-decoration-underline text-white"
                          href="#!"
                        >
                          terms
                        </a>{" "}
                        and{" "}
                        <a
                          className="text-decoration-underline text-white"
                          href="#!"
                        >
                          conditions{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-7 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <div className="text-center text-md-start">
                        <h4 className="mb-0"> Reset your password?</h4>
                        <p className="mb-4">Enter your OTP we'll send you.</p>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-sm-8 col-md">
                          <form className="mb-3">
                            <label className="form-label" htmlFor="card-otp">
                              OTP*
                            </label>
                            <input
                              value={otp}
                              onChange={onInputChangeotp}
                              className="form-control"
                              type="number"
                              placeholder="Enter OTP"
                              // oninput="event.target.value = event.target.value.slice(0, 6)"
                              max={999999}
                            />
                            <div className="mb-3" />
                            <label className="form-label" htmlFor="card-pass">
                              Enter Your Password
                            </label>
                            <div className="position-relative">
                              <input
                                value={password}
                                onChange={onInputChangepassword}
                                className="form-control"
                                type="password"
                                placeholder="Enter Your Password"
                                id="password"
                              />
                              <i
                                className="fa fa-eye position-absolute"
                                id="passwordIcon"
                                onclick="showPassword('password', 'passwordIcon')"
                              />
                            </div>
                            <div className="mb-3" />
                            <label className="form-label" htmlFor="card-confirm">
                              Confirm Password
                            </label>
                            <div className="position-relative">
                              <input
                                value={con_password}
                                onChange={onInputChangeconpassword}
                                className="form-control"
                                type="password"
                                placeholder="Re Enter Your Password"
                                id="password2"
                              />
                              <i
                                className="fa fa-eye position-absolute"
                                id="password2Icon"
                                onclick="showPassword('password2', 'password2Icon')"
                              />
                            </div>
                            <div className="mb-3" />
                            <button
                              disabled={loading}
                              onClick={Submit}
                              className="btn btn-primary d-block w-100 mt-3"
                              type="submit"
                              name="submit"
                            >
                              Submit
                              {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>

  )
}
