import React from 'react'

export default function Totalinvestment() {
  return (
    <div className="container-fluid">
  <div className="card mb-3">
    <div
      className="bg-holder d-none d-lg-block bg-card"
      style={{
        backgroundImage:
          "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
      }}
    />
    {/*/.bg-holder*/}
    <div className="card-body position-relative">
      <div className="row">
        <div className="col-lg-8">
          <h3 className="mb-0">Total Investment Details</h3>
        </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div
      id="tableExample3"
      className="padding-15 card-body"
      data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
    >
      <div className="row justify-content-end g-0">
        <div className="col-auto col-sm-5 mb-3">
          <form>
            <div className="input-group">
              <input
                className="form-control form-control-sm shadow-none search"
                type="search"
                placeholder="Search..."
                aria-label="search"
              />
              <div className="input-group-text bg-transparent">
                <span className="fa fa-search fs-10 text-600" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="table-responsive scrollbar">
        <table className="table table-sm table-striped fs-10 mb-0 overflow-hidden">
          <thead className="bg-200">
            <tr>
              <th
                className="text-900 sort pe-1 align-middle white-space-nowrap"
                data-sort="TotalInvestmentamount"
              >
                Total Investment Amount
              </th>
              <th
                className="text-900 sort pe-1 align-middle white-space-nowrap"
                data-sort="Averageinvestment"
              >
                Average investment return
              </th>
              <th
                className="text-900 sort align-middle white-space-nowrap text-start pe-4"
                data-sort="InvestmentName"
              >
                {" "}
                Name of the plan that given highest return on investment.
              </th>
            </tr>
          </thead>
          <tbody className="list" id="table-purchase-body">
            <tr className="btn-reveal-trigger">
              <th className="align-middle white-space-nowrap text-start name">
                <a href="#">965874512541</a>
              </th>
              <td className="align-middle white-space-nowrap text-start email">
                Abhi**98547
              </td>
              <td className="align-middle white-space-nowrap text-start email">
                Abhishek
              </td>
              {/* <td>
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn-sm btn btn-primary">View</button>
                </div>
              </td> */}
            </tr>
            <tr className="btn-reveal-trigger">
              <th className="align-middle white-space-nowrap text-start name">
                <a href="#">147852369987</a>
              </th>
              <td className="align-middle white-space-nowrap text-start email">
                Roh**98547
              </td>
              <td className="align-middle white-space-nowrap text-start email">
                Roh**
              </td>
              {/* <td>
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn-sm btn btn-primary">View</button>
                </div>
              </td> */}
            </tr>
            <tr className="btn-reveal-trigger">
              <th className="align-middle white-space-nowrap text-start name">
                <a href="#">147852369987</a>
              </th>
              <td className="align-middle white-space-nowrap text-start email">
                Dee**98547
              </td>
              <td className="align-middle white-space-nowrap text-start email">
                Dee***
              </td>
              {/* <td>
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn-sm btn btn-primary">View</button>
                </div>
              </td>{" "} */}
            </tr>
            <tr className="btn-reveal-trigger">
              <th className="align-middle white-space-nowrap text-start name">
                <a href="#">9854751254125</a>
              </th>
              <td className="align-middle white-space-nowrap text-start email">
                Gor**98547
              </td>
              <td className="align-middle white-space-nowrap text-start email">
                Gor***
              </td>
              {/* <td>
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn-sm btn btn-primary">View</button>
                </div>
              </td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row align-items-center mt-3">
        <div className="pagination d-none" />
        <div className="col">
          <p className="mb-0 fs-10">
            <span
              className="d-none d-sm-inline-block"
              data-list-info="data-list-info"
            />
            <span className="d-none d-sm-inline-block"> —</span>
            <a className="fw-semi-bold" href="#!" data-list-view="*">
              View all
              <span
                className="fas fa-angle-right ms-1"
                data-fa-transform="down-1"
              />
            </a>
            <a className="fw-semi-bold d-none" href="#!" data-list-view="less">
              View Less
              <span
                className="fas fa-angle-right ms-1"
                data-fa-transform="down-1"
              />
            </a>
          </p>
        </div>
        <div className="col-auto d-flex">
          <button
            className="btn btn-sm btn-primary"
            type="button"
            data-list-pagination="prev"
          >
            <span>Previous</span>
          </button>
          <button
            className="btn btn-sm btn-primary px-4 ms-2"
            type="button"
            data-list-pagination="next"
          >
            <span>Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}
